import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >


<path d="M2685 8016 c-106 -18 -197 -47 -286 -91 -66 -34 -146 -82 -159 -95
-3 -3 -25 -21 -50 -40 -83 -63 -200 -195 -258 -291 -27 -44 -27 -55 -3 -68 16
-9 35 3 117 72 164 137 304 214 473 262 398 112 795 15 1124 -274 32 -28 62
-51 66 -51 13 0 31 23 31 40 0 47 -201 268 -320 351 -225 159 -490 226 -735
185z"/>
<path d="M2740 7470 c-192 -23 -402 -166 -514 -350 -49 -79 -56 -107 -30 -117
18 -7 17 -8 100 60 81 65 164 111 275 149 92 32 98 33 269 33 172 0 176 0 270
-33 110 -38 178 -75 269 -144 66 -50 78 -53 94 -26 15 23 -52 126 -137 212
-120 122 -258 193 -413 216 -76 11 -90 11 -183 0z"/>
<path d="M1455 6951 c-51 -23 -101 -79 -114 -128 -15 -54 -15 -2113 0 -2184
15 -68 67 -122 140 -143 73 -22 325 -22 397 -1 62 19 114 68 130 124 9 27 12
209 12 630 l0 591 -170 0 -170 0 0 -90 0 -90 48 -1 c26 -1 58 -2 72 -2 l25 -2
3 -497 2 -498 -155 0 -155 0 0 1070 0 1070 155 0 155 0 0 -310 0 -310 96 0 95
0 -3 329 -3 329 -30 44 c-18 25 -49 52 -75 66 -41 20 -57 22 -230 22 -157 0
-191 -3 -225 -19z"/>
<path d="M2256 6899 c-8 -87 -8 -2383 0 -2404 5 -12 25 -15 97 -15 l90 0 -5
813 c-5 690 -4 802 8 747 7 -36 43 -231 79 -435 36 -203 72 -404 80 -445 7
-41 30 -167 50 -280 20 -113 44 -248 53 -300 l17 -95 76 -3 75 -3 38 213 c76
428 117 654 123 675 5 21 2 22 -24 17 -21 -4 -35 -1 -46 11 -23 22 -21 32 7
50 19 13 29 14 45 6 12 -7 22 -7 26 -2 3 5 30 146 60 313 31 167 57 308 60
313 2 6 1 -351 -2 -792 l-6 -803 91 0 92 0 0 1245 0 1246 -92 -3 -93 -3 -16
-80 c-10 -44 -18 -111 -19 -150 0 -38 -8 -88 -16 -110 -19 -47 -94 -426 -94
-470 0 -30 52 -306 71 -377 7 -29 6 -44 -5 -65 -22 -43 -76 -90 -128 -112 -36
-15 -47 -26 -52 -48 -3 -15 -17 -91 -31 -168 -44 -246 -59 -315 -65 -315 -4 0
-17 55 -29 123 -12 67 -33 183 -46 257 -14 74 -25 141 -25 148 0 7 -17 31 -38
55 -21 23 -42 51 -46 61 -3 10 1 59 10 108 9 49 13 103 10 121 -11 54 -96 515
-117 632 -10 61 -31 174 -46 253 l-26 142 -92 0 -92 0 -7 -71z"/>
<path d="M3700 6954 c-19 -8 -50 -29 -67 -46 -66 -64 -64 -11 -61 -1199 l3
-1074 22 -42 c27 -49 68 -80 130 -99 66 -20 323 -19 391 1 62 19 114 68 130
124 16 53 17 2157 1 2216 -13 49 -51 92 -104 116 -34 16 -66 19 -225 18 -143
0 -193 -3 -220 -15z m370 -1229 l0 -1055 -155 0 -155 0 0 1055 0 1055 155 0
155 0 0 -1055z"/>
<path d="M6090 5725 l0 -1245 248 0 c198 0 259 3 302 16 65 19 114 64 129 119
16 58 15 2179 -1 2226 -18 53 -39 78 -90 104 -47 25 -50 25 -318 25 l-270 0 0
-1245z m500 5 l0 -1070 -155 0 -155 0 0 1070 0 1070 155 0 155 0 0 -1070z"/>
<path d="M7940 6885 l0 -85 105 0 105 0 0 -1160 0 -1160 95 0 95 0 2 1158 3
1157 103 3 102 3 0 84 0 85 -305 0 -305 0 0 -85z"/>
<path d="M2752 6947 c-78 -29 -147 -115 -158 -199 -8 -61 17 -143 58 -190 33
-36 104 -78 133 -78 8 0 15 -4 15 -8 0 -4 -15 -86 -34 -182 -97 -492 -108
-558 -95 -582 29 -55 110 -84 209 -75 88 8 160 54 160 103 0 13 -31 181 -70
375 -38 193 -70 355 -70 360 0 5 20 18 45 30 117 55 167 208 106 326 -55 107
-187 160 -299 120z"/>
<path d="M4500 5720 l0 -1240 90 0 90 0 0 574 c0 506 2 575 15 580 19 7 272 6
291 -1 12 -4 14 -104 14 -579 l0 -574 95 0 95 0 0 1240 0 1240 -95 0 -95 0 -2
-577 -3 -578 -155 0 -155 0 -3 578 -2 577 -90 0 -90 0 0 -1240z"/>
<path d="M7240 6940 c0 -28 -32 -338 -85 -825 -43 -388 -91 -831 -135 -1245
-11 -102 -25 -221 -30 -264 -17 -138 -26 -126 93 -126 l104 0 17 203 c9 111
19 214 22 230 l6 27 148 0 149 0 5 -42 c10 -70 46 -394 46 -407 0 -7 32 -11
100 -11 l100 0 0 28 c0 15 -11 128 -24 252 -37 343 -74 676 -96 875 -11 99
-36 324 -55 500 -18 176 -46 434 -62 573 l-27 252 -138 0 c-132 0 -138 -1
-138 -20z m168 -697 c70 -741 99 -1077 95 -1085 -2 -5 -58 -8 -123 -8 -65 0
-121 3 -123 8 -2 4 4 93 14 197 10 105 25 267 34 360 8 94 19 215 25 270 32
336 40 422 40 457 0 21 4 38 8 38 4 0 18 -107 30 -237z"/>
<path d="M8956 6888 c-4 -40 -32 -300 -62 -578 -30 -278 -68 -631 -84 -785
-17 -154 -35 -316 -40 -360 -14 -115 -70 -647 -70 -667 0 -16 11 -18 99 -18
l99 0 6 28 c3 15 13 108 21 207 9 99 18 190 21 203 4 22 7 22 153 22 l149 0
11 -102 c7 -57 18 -157 26 -223 8 -67 15 -124 15 -128 0 -4 46 -7 101 -7 l102
0 -7 63 c-3 34 -13 130 -21 212 -8 83 -24 227 -35 320 -11 94 -28 256 -40 360
-32 312 -79 740 -115 1065 -18 168 -37 340 -40 383 l-7 77 -138 0 -137 0 -7
-72z m162 -540 c6 -73 20 -230 31 -348 51 -542 71 -773 71 -810 l0 -40 -119 0
c-66 0 -122 4 -125 8 -2 4 4 97 15 207 10 110 28 304 39 430 11 127 27 291 35
366 8 75 18 177 21 227 4 51 10 92 14 92 3 0 11 -60 18 -132z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
